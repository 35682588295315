.form-checkbox {
  @include rem(margin, 0 0 6px);
  width: 100%;
  &.MuiFormControlLabel-root {
    @include rem(margin, 0 0 6px);
  }
  .MuiCheckbox-root {
    padding: 0;
    @include rem(margin, 0 4px 0 0);
  }
  .check-label {
    line-height: 1.25;
    font-family: $font-base;
    @include rem(font-size, 12px);
    font-weight: $w-semibold;
    color: $clr-text-dark;
    .main-text {}
  }
  &.Mui-disabled {
    .check-label {
      color: $clr-text-light;
      .secondary-text {
        color: rgba($clr-text-light, 0.5);
      }
    }
  }
}
