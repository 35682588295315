.form-control {
  display: block;
  width: 100%;
  @include rem(height, 34px);
  @include rem(font-size, 12px);
  @include rem(padding, 0 12px);
  color: $clr-text-dark;
  @include rem(border, 1px solid $clr-border);
  @include rem(border-radius, $border-radius-base);
  line-height: 1.24;
  font-weight: $w-semibold;
  box-shadow: none;
  &::placeholder {
    opacity: 1;
    color: $clr-text-light;
  }
  &:disabled {
    background-color: $clr-bg;
    color: $clr-text-med;
  }
  &:focus {
    box-shadow: none;
    border-color: $clr-border;
  }
  &.size-lg {
    @include rem(height, 45px);
    @include rem(font-size, 14px);
    font-weight: $w-normal;
    @include rem(padding, 0 $gutter);
  }
}
