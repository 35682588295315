/* BASE ********/
* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-weight: inherit;
}

html {
  @include rem-baseline;
}

body {
  @include rem(padding-top, 70px);
  @include rem(padding-bottom, 74px);
  min-height: 100vh;
  position: relative;
  font-family: $font-base;
  text-align: center;
  @include rem(font-size, 14px);
  background-color: $clr-bg;
  line-height: 1;
  color: $clr-text-dark;
  font-weight: $w-normal;
  background-image: url('../img/diagonal-bg.png');
  background-size: 100% auto;
  @include rem(background-position, center 70px);
  background-repeat: no-repeat;
}

a {
  text-decoration: none;
  color: $clr-primary;
  &:hover {
    text-decoration: none;
  }
}

ul, ol {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

button {
  padding: 0;
  margin: 0;
  border: 0;
  background-color: transparent;
}

p {
  margin-bottom: 0;
  line-height: 1.24;
}

/* Hide number input arrow Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide number input arrow in Firefox */
input[type=number] {
  -moz-appearance:textfield;
}
