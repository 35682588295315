.topbar {
  background-color: $clr-white;
  @include rem(height, 70px);
  font-family: $font-alter;
  font-weight: $w-bold;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: $z-topbar;
  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    .logo {
      display: block;
      @include rem(width, 124px);
      flex: 0 0 auto;
      img {
        width: 100%;
      }
    }
    .topbar-nav {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      .navigation {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        li {
          @include rem(margin, 0 15px);
          a {
            display: inline-block;
            @include rem(font-size, 14px);
            text-transform: uppercase;
            color: $clr-text-dark;
            @include rem(letter-spacing, 1px);
            transition: $transition;
            &:hover {
              color: $clr-primary;
            }
          }
        }
      }
      .user {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        @include rem(margin, 0 15px);
        .material-icons {
          @include rem(margin-right, 8px);
        }
        div {
          line-height: 1.24;
          .name {
            display: block;
          }
          .logout {
            display: block;
            @include rem(font-size, 12px);
            color: $clr-text-med
          }
        }
      }
    }
  }
}
