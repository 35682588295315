.square-icon-btn {
  color: $clr-primary;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @include rem(width, 34px);
  @include rem(height, 34px);
  @include rem(border, 2px solid $clr-primary);
  @include rem(border-radius, $border-radius-base);
  &:hover {
    background-color: $clr-primary;
    color: $clr-white;
  }
  &[disabled] {
    border-color: $clr-text-light;
    color: $clr-text-light;
  }
}
