.tabs-line {
  @include rem(border-bottom, 1px solid $clr-border-light);
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  .tab-line {
    flex: 1 1 auto;
    @include rem(font-size, 12px);
    font-weight: $w-semibold;
    line-height: 1.24;
    @include rem(padding, 10px);
    color: $clr-text-light;
    position: relative;
    transition: $transition;
    text-align: center;
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      @include rem(height, 3px);
      background-color: $clr-orange;
      opacity: 0;
    }
    &:hover {
      color: $clr-text-dark;
    }
    /* active */
    &.active {
      color: $clr-text-dark;
      &:after {
        opacity: 1;
      }
    }
    /* disabled */
    &[disabled] {
      &.active {
        color: $clr-text-light;
      }
      &:after {
        background-color: $clr-border-light;
      }
    }
  }
}
