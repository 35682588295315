.label-group {
  position: relative;
  @include rem(padding, 2px 0 0 24px);
  @include rem(margin-bottom, 8px);
  [class^='icomoon-'] {
    position: absolute;
    left: 0;
    top: 0;
    @include rem(font-size, 20px);
  }
  .form-label {
    margin-bottom: 0;
  }
}
