.moment-selector {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @include rem(margin-bottom, 20px);
  &.MuiFormGroup-root {
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    @include rem(height, 50px);
    @include rem(padding-right, 20px);
    .MuiRadio-colorSecondary.Mui-checked {
      color: $clr-orange;
      &:hover {
        background-color: rgba($clr-orange, 0.04)!important;
      }
    }
    .radio-label {
      display: inline-block;
      font-family: $font-alter;
      @include rem(font-size, 16px);
      font-weight: $w-bold;
    }
    .form-group {
      margin-bottom: 0;
      @include rem(margin-left, 16px);
    }
  }
  .or {
    @include rem(font-size, 16px);
    font-weight: $w-bold;
    color: $clr-white;
    font-family: $font-alter;
    flex: 0 0 auto;
    @include rem(margin, 0 20px);
  }
}
