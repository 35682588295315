.btn {
  border: 0;
  @include rem(font-size, 14px);
  text-transform: uppercase;
  font-family: $font-alter;
  font-weight: $w-bold;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @include rem(min-height, 52px);
  @include rem(border-radius, $border-radius-base);
  @include rem(padding, 8px 24px);
  cursor: pointer;
  transition: $transition;
  @include rem(border, 2px solid transparent);
  [class^="icomoon"] {
    @include rem(margin-left, 10px);
  }
  &.block {
    width: 100%;
  }
}

.btn-primary {
  background-color: $clr-primary;
  border-color: $clr-primary;
  color: $clr-white;
  &:hover {
    background-color: lighten($clr-primary, 5%);
    border-color: lighten($clr-primary, 5%);
    color: $clr-white;
  }
}

.btn-orange {
  background-color: $clr-orange;
  border-color: $clr-orange;
  color: $clr-white;
  &:hover {
    background-color: lighten($clr-orange, 5%);
    border-color: lighten($clr-orange, 5%);
    color: $clr-white;
  }
}

.btn-green {
  background-color: $clr-green;
  border-color: $clr-green;
  color: $clr-white;
  &:hover {
    background-color: lighten($clr-green, 5%);
    border-color: lighten($clr-green, 5%);
    color: $clr-white;
  }
}

.btn-black {
  background-color: $clr-text-dark;
  border-color: $clr-text-dark;
  color: $clr-white;
  &:hover {
    background-color: lighten($clr-text-dark, 5%);
    border-color: lighten($clr-text-dark, 5%);
    color: $clr-white;
  }
}

.btn-black-outline {
  background-color: transparent;
  color: $clr-text-dark;
  @include rem(border, 2px solid $clr-text-dark);
  &:hover {
    background-color: transparent;
    border-color: lighten($clr-text-dark, 5%);
    color: lighten($clr-text-dark, 5%);
  }
}
