.single-select {
  .single-select__control {
    @include rem(min-height, 34px);
    @include rem(border-radius, $border-radius-base);
    border-color: $clr-border!important;
    padding: 0;
    @include rem(padding, 0 0 0 12px);
    box-shadow: none!important;
    .single-select__value-container {
      padding: 0;
      .single-select__single-value,
      .single-select__placeholder {
        margin: 0;
        font-weight: $w-semibold;
        @include rem(font-size, 12px);
      }
      .single-select__placeholder {
        color: $clr-text-med;
      }
      .single-select__input {}
    }
    .single-select__indicators {
      .single-select__indicator-separator {
        display: none;
      }
      .single-select__indicator {
        color: $clr-text-dark;
        padding-top: 6px;
        padding-bottom: 6px;
      }
    }
  }
  .single-select__menu {
    margin: 0;
    border-color: $clr-border;
    .single-select__menu-list {
      .single-select__option {
        @include rem(font-size, 12px);
        color: $clr-text-dark;
        font-weight: $w-medium;
        &.single-select__option--is-focused {
          background-color: $clr-bg;
        }
      }
    }
  }
  &.single-select--is-disabled {
    .single-select__control {
      background-color: $clr-bg;
      .single-select__indicators {
        .single-select__indicator {
          color: $clr-text-med;
        }
      }
    }
  }
}
