.solution-type-title {
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @include rem(padding, 10px 16px);
  position: relative;
  [class^='icomoon-'] {
    @include rem(margin, 0 8px 0 -12px);
  }
  .tit {
    text-transform: uppercase;
    @include rem(font-size, 12px);
    font-weight: $w-semibold;
    margin: 0;
  }
  &:after {
    content: '';
    position: absolute;
    @include rem(left, calc(50% - 4px));
    @include rem(bottom, -4px);
    display: block;
    @include rem(width, 8px);
    @include rem(height, 8px);
    background-color: $clr-white;
    transform: rotate(45deg);
  }
}
