.result-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @include rem(margin-bottom, 4px);
  @include rem(font-size, 12px);
  @include rem(padding, 4px 14px);
  @include rem(min-height, 30px);
  .term {
    font-weight: $w-semibold;
  }
  .description {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    @include rem(margin-left, 16px);
    text-align: right;
    .action {
      @include rem(margin-left, 22px);
      color: $clr-text-med;
      [class^='icomoon-'] {
        @include rem(font-size, 20px);
      }
    }
  }
}
