.star-checkboxes {
  display: flex;
  flex-direction: row!important;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  @include rem(margin, 0 -3px);
  .form-checkbox {
    @include rem(margin-left, 3px);
    @include rem(margin-right, 3px);
    @include rem(width, calc(50% - 6px));
    &.Mui-disabled {
      .stars-row {
        .material-icons {
          color: $clr-text-med;
        }
      }
    }
  }
}


