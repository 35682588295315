/* STYLES ********/
#root,
#App {
  min-height: 100%;
}
.container {
  width: 100%;
  @include rem(max-width, $container-max-width);
  @include rem(padding, 0 $gutter);
  text-align: left;
  margin: 0 auto;
  &.container-fluid {
    max-width: 100%;
  }
}

.hidden {
  display: none!important;
}

.bg-primary {
  background-color: $clr-primary!important;
  color: $clr-white;
}

.bg-green {
  background-color: $clr-green!important;
  color: $clr-white;
}

.bg-red {
  background-color: $clr-red!important;
  color: $clr-white;
}

.bg-yellow {
  background-color: $clr-yellow!important;
  color: $clr-white;
}

.bg-orange {
  background-color: $clr-orange!important;
  color: $clr-white;
}

[class^='icomoon-'] {
  @include rem(font-size, 24px);
}

/* BTN TEXT ******/
.btn-text {
  display: inline-block;
  text-transform: uppercase;
  font-weight: $w-semibold;
  @include rem(font-size, 12px);
  color: $clr-primary;
  .disabled & {
    color: inherit;
  }
}

/* COLLAPSE TOGGLE ********/
.btn-toggle-collapse {
  color: $clr-primary;
  background-color: $clr-bg;
  @include rem(border-radius, $border-radius-base);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @include rem(font-size, 12px);
  font-weight: $w-semibold;
  width: 100%;
  @include rem(min-height, 28px);
  @include rem(padding, 4px 16px);
  .material-icons {
    @include rem(margin-left, 8px);
  }
  .disabled & {
    color: $clr-text-med;
  }
}

.stars-row {
  line-height: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  .material-icons {
    color: $clr-primary;
    @include rem(font-size, 16px);
  }
  .disabled & {
    .material-icons {
      color: $clr-text-light;
    }
  }
}

.tags-row {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  .tag {
    @include rem(margin, 0 4px 4px 0);
  }
}

/* BOXES ********/
.box {
  text-align: left;
  background-color: $clr-white;
  @include rem(border-radius, 6px);
  @include rem(box-shadow, 0 0 13px 0 rgba($clr-black, 0.18));
  color: $clr-text-dark;
  &.disabled {
    color: $clr-text-light;
    pointer-events: none;
    .box-header {
      background-color: $clr-white;
    }
    .styled-switch {
      pointer-events: initial!important;
    }
  }
}

.box-body {
  @include rem(padding, 16px);
  &.no-solutions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    p {
      text-transform: uppercase;
      text-align: center;
      @include rem(font-size, 12px);
      font-weight: $w-semibold;
      color: $clr-text-light;
    }
  }
}

.boxes-row {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  @include rem(margin, 0 -10px 20px);
  .box {
    @include rem(margin, 0 10px);
    @include rem(width, calc(20% - 20px));
  }
}

/* TEXT ********/
.text-primary {
  color: $clr-primary!important;
  .disabled & {
    color: inherit!important;
  }
}

.text-green {
  color: $clr-green;
  .disabled & {
    color: inherit;
  }
}

.text-orange {
  color: $clr-orange;
  .disabled & {
    color: inherit;
  }
}

.title-lg {
  display: block;
  line-height: 1.14;
  @include rem(font-size, 48px);
  font-family: $font-alter;
  font-weight: $w-bold;
  @include rem(margin-bottom, $gutter);
}

.title-md {
  display: block;
  line-height: 1.24;
  @include rem(font-size, 24px);
  font-family: $font-alter;
  font-weight: $w-light;
  @include rem(margin-bottom, 20px);
}

.title-sm {
  display: block;
  line-height: 1.24;
  @include rem(font-size, 16px);
  font-family: $font-alter;
  font-weight: $w-bold;
}

.title-group {
  text-align: center;
  color: $clr-white;
  @include rem(padding-top, 44px);
  @include rem(margin-bottom, 20px);
}

.total-title {
  display: block;
  @include rem(font-size, 12px);
  font-weight: $w-semibold;
  @include rem(padding-right, 4px);
  background-color: $clr-white;
  position: relative;
  @include rem(margin-bottom, 6px);
  color: $clr-text-med;
  &:after {
    content: '';
    display: block;
    position: absolute;
    @include rem(height, 1px);
    @include rem(left, 36px);
    right: 0;
    top: 50%;
    background-color: $clr-border-light;
  }
}

.solution-amount {
  text-align: center;
  position: relative;
  @include rem(padding, 1px 0 16px);
  @include rem(border-bottom, 1px solid $clr-border-light);
  @include rem(margin-bottom, 16px);
  .amount {
    font-family: $font-alter;
    font-weight: $w-extrabold;
    @include rem(font-size, 18px);
    display: block;
    @include rem(padding, 0 46px);
    @include rem(margin-bottom, 10px);
  }
  .styled-switch {
    position: absolute;
    right: 0;
    top: 0;
  }
  .btn-text {
    margin: 0 auto;
  }
}

.ico-list-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  @include rem(margin-bottom, 10px);
  .material-icons {
    @include rem(font-size, 16px);
    @include rem(margin-right, 4px);
  }
}

.facts-list {
  @include rem(padding, 6px 10px 0);
  .fact-row {
    @include rem(font-size, 12px);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    @include rem(margin-bottom, 6px);
    .term {
      @include rem(margin-right, 8px);
    }
    .description {
      text-align: right;
      @include rem(margin-left, 8px);
    }
  }
}

.ico-text {
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @include rem(padding, 10px 0);
  .material-icons {
    @include rem(margin-right, 6px);
  }
  p {
    @include rem(font-size, 12px);
    font-weight: $w-bold;
  }
}

/* FORMS ********/
.form-group {
  @include rem(margin-bottom, $gutter);
}

.boxed-item {
  background-color: $clr-bg;
  @include rem(padding, 5px);
  @include rem(border-radius, $border-radius-base);
  @include rem(margin-bottom, 4px);
  &.is-dismissible {
    position: relative;
    @include rem(margin-bottom, 8px);
    .MuiIconButton-root {
      position: absolute;
      @include rem(right, -8px);
      @include rem(top, -8px);
      @include rem(border, 2px solid $clr-bg);
      background-color: $clr-white;
      @include rem(padding, 2px);
      .material-icons {
        @include rem(font-size, 12px);
      }
    }
    .facts-list {
      width: 100%;
    }
  }
}

.input-group {
  position: relative;
  .form-control {
    @include rem(padding-right, 44px);
  }
  .append {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    @include rem(height, 34px);
    @include rem(width, 34px);
    pointer-events: none;
  }
}

.form-row {
  @include rem(margin, 0 -8px);
  .form-group {
    @include rem(margin-left, 8px);
    @include rem(margin-right, 8px);
  }
  .percent-30 {
    @include rem(width, calc(30% - 16px));
  }
  .percent-40 {
    @include rem(width, calc(40% - 16px));
  }
  .percent-50 {
    @include rem(width, calc(50% - 16px));
  }
  .percent-60 {
    @include rem(width, calc(60% - 16px));
  }
}

.add-row {
  @include rem(padding-right, 50px);
  position: relative;
  .square-icon-btn {
    position: absolute;
    @include rem(right, 10px);
    @include rem(bottom, 16px);
  }
}

.results-list {
  @include rem(margin-bottom, 10px);
}

/* TABLE */
.table {
  margin-bottom: 0;
  @include rem(font-size, 12px);
  thead th {
    font-weight: $w-semibold;
    text-transform: uppercase;
    background-color: $clr-bg;
    border: 0;
    @include rem(padding, 10px 12px);
    text-align: left;
  }
  tbody td {
    text-align: left;
    vertical-align: middle;
    @include rem(padding, 10px 12px);
    border-color: $clr-border-light;
    font-family: $font-alter;
    line-height: 1.4;
  }
  tbody tr:last-of-type td {
    border-bottom: 0;
  }
  .btn {
    @include rem(min-height, 30px);
    @include rem(font-size, 12px);
    @include rem(padding, 6px 14px);
    @include rem(width, 94px);
  }
  .ico-text {
    align-items: center;
    justify-content: flex-start;
    text-align: inherit;
    padding: 0;
  }
  .price {
    line-height: 1;
    @include rem(font-size, 16px);
    display: block;
    font-weight: $w-semibold;
    white-space: nowrap;
  }
  .separator {
    td {
      padding: 0;
      background-color: $clr-border-light;
      @include rem(height, 2px);
      font-size: 0;
    }
  }
  .strong {
    font-weight: $w-bold;
    .price {
      font-weight: $w-extrabold;
    }
  }
  .table-tit {
    @include rem(font-size, 14px);
    font-weight: $w-bold;
  }
  &.align-cells-top {
    td {
      vertical-align: top;
    }
  }
}

/* AIRCRAFT COMPANIES */
.aircraft-companies {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  .aircraft-logo {
    flex: 1 1 auto;
    @include rem(max-width, 128px);
    @include rem(height, 34px);
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .plus {
    @include rem(margin, 0 10px);
    @include rem(font-size, 18px);
    font-weight: $w-normal;
  }
}

/* FLIGHT TRAVEL */
.flight-travel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: $w-normal;
  margin-left: auto;
  margin-right: auto;
  .from,
  .to {
    @include rem(font-size, 14px);
    line-height: 1.2;
    .date {
      display: block;
    }
    .time {
      display: block;
      @include rem(font-size, 18px);
      font-weight: $w-bold;
    }
  }
  .from {
    text-align: right;
  }
  .infography {
    flex: 0 0 auto;
    @include rem(width, 154px);
    @include rem(margin, 0 10px);
  }
  .to {
    text-align: left;
  }
}

/* INFOGRAPHY */
.infography {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include rem(font-size, 14px);
  position: relative;
  @include rem(padding-right, 30px);
  .chart {
    color: $clr-text-light;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    @include rem(height, 24px);
    @include rem(margin-top, -6px);
    @include rem(margin-bottom, -6px);
    .stop {
      display: block;
      @include rem(width, 8px);
      @include rem(height, 8px);
      border-radius: 50%;
      background-color: $clr-orange;
      position: absolute;
      @include rem(top, calc(50% - 4px));
      @include rem(left, 62px);
    }
    .line {
      display: block;
      @include rem(height, 3px);
      width: 100%;
      background-color: $clr-text-light;
    }
    [class^='icomoon-'] {
      position: absolute;
      right: 0;
      @include rem(top, calc(50% - 12px));
    }
  }
}

/* TITLE TD */
.title-td {
  border: 0;
  .flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .material-icons {
      @include rem(margin-right, 8px);
    }
    .tit {
      text-transform: uppercase;
      @include rem(font-size, 12px);
      font-weight: $w-semibold;
      font-family: $font-base;
    }
  }
}

/* MODAL */
.modal-content {
  border: 0;
  @include rem(border-radius, $border-radius-base);
  .modal-header {
    border: 0;
    @include rem(padding, 16px 68px 10px 14px);
    position: relative;
    .close {
      text-shadow: none;
      opacity: 1;
      padding: 0;
      margin: 0;
      @include rem(font-size, 36px);
      @include rem(height, 36px);
      line-height: 1;
      font-weight: $w-normal;
      position: absolute;
      @include rem(top, calc(50% - 18px));
      @include rem(right, 20px);
    }
  }
  .modal-body {
    @include rem(padding, 0 0 10px);
  }
}

/* MODAL SOLUTION HEADER */
.modal-solution-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex: 1 1 auto;
    [class^='icomoon-'] {
      @include rem(margin-right, 10px);
    }
    .modal-title {
      text-transform: uppercase;
      @include rem(font-size, 16px);
      font-family: $font-alter;
      font-weight: $w-normal;
      strong {
        font-weight: $w-bold;
      }
    }
  }
  .btn {
    flex: 0 0 auto;
    @include rem(margin-left, 16px);
    @include rem(min-height, 40px);
    @include rem(padding, 4px 12px);
  }
}
