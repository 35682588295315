.price-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  .price {
    width: 100%;
    @include rem(margin-bottom, 10px);
  }
}
