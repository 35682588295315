.acmi-box {
  .form-row {

  }
  .boxed-item {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
  }
}
