@import '../../../node_modules/sass-rem/rem';
@import '../Variables';

.home-screen {
  .submit-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @include rem(margin-bottom, 34px);
    .advice {
      color: $clr-text-light;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      @include rem(max-width, 292px);
      .material-icons {
        @include rem(margin-right, 12px);
      }
      .advice-text {
        margin: 0;
        @include rem(font-size, 12px);
        font-weight: $w-semibold;
        line-height: 1.4;
      }
    }
    .buttons-holder {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      .term {
        text-transform: uppercase;
        font-weight: $w-bold;
        font-family: $font-alter;
        @include rem(margin-left, 16px);
      }
      .btn {
        @include rem(margin-left, 16px);
      }
    }
  }
}
