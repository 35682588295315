.form-radio {
  @include rem(margin, 4px 16px 4px 0!important);
  align-items: flex-start!important;
  .MuiRadio-root {
    padding: 0;
    @include rem(margin-right, 4px);
  }
  .radio-label {
    font-family: $font-base;
    @include rem(font-size, 12px);
    font-weight: $w-semibold;
    @include rem(margin-top, 1px);
    color: $clr-text-dark;
    .main-text {
      display: block;
    }
    .secondary-text {
      color: $clr-text-med;
    }
  }
  &.Mui-disabled {
    .radio-label {
      color: $clr-text-light;
      .secondary-text {
        color: rgba($clr-text-light, 0.5);
      }
    }
  }
}
