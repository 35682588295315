.box-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @include rem(min-height, 45px);
  background-color: $clr-bg;
  @include rem(border-radius, 6px 6px 0 0);
  @include rem(padding, 4px 10px);
  .box-title-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex: 1 1 auto;
    [class^='icomoon-'] {
      @include rem(margin-right, 8px);
    }
    .title-sm {
      @include rem(margin-right, 8px);
      margin-bottom: 0;
    }
  }
  .styled-switch {
    flex: 0 0 auto;
  }
}
