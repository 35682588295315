@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?7f1rxd');
  src:  url('../fonts/icomoon.eot?7f1rxd#iefix') format('embedded-opentype'),
  url('../fonts/icomoon.ttf?7f1rxd') format('truetype'),
  url('../fonts/icomoon.woff?7f1rxd') format('woff'),
  url('../fonts/icomoon.svg?7f1rxd#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icomoon-"], [class*=" icomoon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icomoon-calendar:before {
  content: "\e91b";
}
.icomoon-arrow_right:before {
  content: "\e900";
}
.icomoon-bus:before {
  content: "\e901";
}
.icomoon-case:before {
  content: "\e902";
}
.icomoon-check_circle:before {
  content: "\e903";
}
.icomoon-check:before {
  content: "\e904";
}
.icomoon-city:before {
  content: "\e905";
}
.icomoon-clock:before {
  content: "\e906";
}
.icomoon-cog_double:before {
  content: "\e907";
}
.icomoon-company:before {
  content: "\e908";
}
.icomoon-cross_circle:before {
  content: "\e909";
}
.icomoon-flight_arrival:before {
  content: "\e90a";
}
.icomoon-flight_departure:before {
  content: "\e90b";
}
.icomoon-hotel_bed:before {
  content: "\e90c";
}
.icomoon-hourglass:before {
  content: "\e90d";
}
.icomoon-jet:before {
  content: "\e90e";
}
.icomoon-location_airport_departure:before {
  content: "\e90f";
}
.icomoon-location_pin_airport:before {
  content: "\e910";
}
.icomoon-location_pin_area:before {
  content: "\e911";
}
.icomoon-money_coins:before {
  content: "\e912";
}
.icomoon-person_standing:before {
  content: "\e913";
}
.icomoon-pin_outline:before {
  content: "\e914";
}
.icomoon-refresh:before {
  content: "\e915";
}
.icomoon-star:before {
  content: "\e916";
}
.icomoon-store:before {
  content: "\e917";
}
.icomoon-trash:before {
  content: "\e918";
}
.icomoon-wallet:before {
  content: "\e919";
}
.icomoon-warning_circle:before {
  content: "\e91a";
}
