@import '../../../node_modules/sass-rem/rem';
@import '../Variables';

.login-screen {

  position: relative;
  color: $clr-white;
  @include rem(padding-bottom, 120px);
  @include rem(min-height, calc(100vh - 144px));

  .container {
    text-align: center;
    > * {
      position: relative;
      z-index: 15;
    }
    .title-group {
      @include rem(padding-top, 80px);
      @include rem(margin-bottom, 34px);
    }
  }

  .login-box {
    margin: 0 auto;
    @include rem(max-width, 442px);
    @include rem(padding, 30px 34px);
    .login-form {
      .form-control {
        font-weight: $w-normal;
      }
      .forgot-password {
        display: inline-block;
        @include rem(margin-bottom, $gutter);
      }
    }
  }

  .bg-video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .validation-error {
    font-weight: $w-semibold;
    color: $clr-error;
    @include rem(margin, 10px 0);
  }

}


