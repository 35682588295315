.custom-calendar {
  @include rem(min-width, 328px!important);
  border: 0!important;
  @include rem(box-shadow, 0 0 13px 0 rgba($clr-black, 0.18));
  display: flex!important;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__navigation {
    color: $clr-primary;
    @include rem(top, 22px);
    &.react-datepicker__navigation--previous {
      border-right-color: $clr-primary;
    }
    &.react-datepicker__navigation--next {
      border-left-color: $clr-primary;
      @include rem(right, 94px);
    }
  }
  .react-datepicker__month-container {
    float: none!important;
    .react-datepicker__header {
      @include rem(padding-top, 18px);
      background-color: transparent;
      border-bottom: 0;
      .react-datepicker__current-month {
        display: block;
        color: $clr-primary;
        font-family: $font-alter;
        font-weight: $w-medium;
        @include rem(font-size, 20px);
        letter-spacing: 0;
        @include rem(margin-bottom, 16px);
      }
    }
    .react-datepicker__day-names {
      .react-datepicker__day-name {
        @include rem(font-size, 12px);
        font-weight: $w-semibold;
        text-transform: uppercase;
        color: $clr-text-med;
      }
    }
    .react-datepicker__month {
      margin-top: 0;
      @include rem(margin-bottom, 10px);
      .react-datepicker__day--keyboard-selected {
        background-color: $clr-primary;
      }
    }
  }
  .react-datepicker__time-container {
    float: none!important;
    border-left-color: $clr-border-light;
    position: relative!important;
    .react-datepicker__header {
      background-color: $clr-white;
      border-bottom-color: $clr-border-light;
      .react-datepicker-time__header {
        display: block;
        color: $clr-primary;
        font-family: $font-alter;
        font-weight: $w-semibold;
        @include rem(font-size, 14px);
        text-transform: uppercase;
      }
    }
    .react-datepicker__time {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      @include rem(top, 31px);
      height: auto!important;
      .react-datepciker__time-box {
        height: 100%!important;
        .react-datepicker__time-list {
          height: 100%!important;
        }
      }
    }
  }
}
