.payment-screen {

  @include rem(padding-bottom, 26px);

  /* BREADCRUMBS */
  .breadcrumbs {
    @include rem(padding-top, 16px);
    @include rem(margin-bottom, 6px);
    .ico-text {
      padding: 0;
      color: $clr-white;
      justify-content: flex-start;
    }
  }

  /* TITLE MD */
  .title-md {
    font-weight: $w-bold;
    color: $clr-white;
  }

  /* TIMELINE BOX */
  .timeline-box {
    @include rem(padding, 16px);
    text-align: center;
    .times-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      position: relative;
      * {
        @include rem(width, 46px);
      }
      .step-time {
        position: absolute;
        top: 0;
      }
    }
    .actions-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      @include rem(font-size, 14px);
      font-family: $font-alter;
      font-weight: $w-bold;
      @include rem(padding, 0 60px);
      .start-action,
      .end-action {
        text-transform: uppercase;
        position: absolute;
        bottom: 0;
        left: 0;
        @include rem(min-width, 60px);
      }
      .end-action {
        left: auto;
        right: 0;
      }
      .step-action {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }

  /* TIMELINE */
  .timeline {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    @include rem(padding, 10px);
    .timeline-circle {
      @include rem(width, 24px);
      @include rem(height, 24px);
      border-radius: 50%;
    }
    .timeline-line {
      flex: 1 1 auto;
      @include rem(height, 6px);
      background: $clr-green;
      background: linear-gradient(90deg, $clr-orange 0%, $clr-green 100%);
    }
    .step {
      @include rem(width, 18px);
      @include rem(height, 18px);
      position: absolute;
      top: calc(50% - 9px);
      border-radius: 50%;
      @include rem(border, 3px solid $clr-white);
      background-color: $clr-text-light;
    }
  }

  /* BOXES */
  .box {
    @include rem(margin-bottom, 16px);
    .box-header {
      background: transparent;
    }
    .solution-type-title {
      @include rem(max-width, 220px);
      &:after {
        display: none;
      }
    }
    .table {
      tbody td {
        @include rem(font-size, 14px);
      }
    }
  }

  /* CREDIT BOX */
  .credit-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @include rem(padding, 16px);
    > p {
      text-transform: uppercase;
      font-family: $font-alter;
      font-weight: $w-bold;
      @include rem(width, 126px);
      text-align: left;
      @include rem(margin, 0 16px 0 0);
    }
    .credit-area {
      text-align: right;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      .text {
        line-height: 1.24;
        .price {
          font-family: $font-alter;
          @include rem(font-size, 18px);
          font-weight: $w-extrabold;
          display: block;
        }
      }
      [class^='icomoon-'] {
        @include rem(font-size, 38px);
        @include rem(margin-left, 16px);
      }
    }
  }

  /* CHECKOUT BOX */
  .checkout-box {
    .tit {
      font-family: $font-alter;
      text-transform: uppercase;
      color: $clr-primary;
      @include rem(font-size, 16px);
      font-weight: $w-bold;
      @include rem(padding, 16px);
      line-height: 1.24;
      margin: 0;
    }
    .line {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      @include rem(border-top, 2px solid $clr-bg);
      @include rem(padding, 12px 16px);
      .label-group {
        margin: 0;
      }
      > span {
        font-family: $font-alter;
        @include rem(font-size, 18px);
        @include rem(line-height, 24px);
        &.big {
          @include rem(font-size, 24px);
          font-weight: $w-extrabold;
        }
        &.price {
          font-weight: $w-extrabold;
        }
      }
    }
  }

}
