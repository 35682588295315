.footer {
  background-color: $clr-white;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  @include rem(height, 74px);
  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    .logo-group {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      @include rem(padding, 10px 22px);
      margin: 0;
      .caption {
        text-transform: uppercase;
        @include rem(font-size, 12px);
        font-weight: $w-semibold;
        color: $clr-text-med;
        @include rem(margin-right, 16px);
      }
    }
    .logo {
      @include rem(width, 124px);
    }
  }
}
