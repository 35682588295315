.results-screen {
  @include rem(padding-top, 28px);

  .container {
    text-align: center;
  }

  .showing-box {
    margin: 0 auto;
    @include rem(margin-bottom, 28px);
    display: inline-flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    .tit {
      text-align: center;
      @include rem(font-size, 16px);
      font-family: $font-alter;
      flex: 1 1 auto;
      @include rem(padding, 10px 16px);
      margin: 0;
      white-space: nowrap;
      strong {
        font-weight: $w-bold;
      }
    }
    .btn {
      flex: 0 0 auto;
      @include rem(padding, 0 16px);
      background-color: transparent;
      color: $clr-orange;
      @include rem(min-height, 40px);
      border: 0;
      @include rem(border-left, 1px solid $clr-border-light);
    }
  }

  /* RESULTS SUBMIT ROW */
  .results-submit-row {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    @include rem(margin-bottom, 34px);
    > * {
      flex: 1 1 auto;
    }
    /* state switch area */
    .state-switch-area {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      @include rem(max-width, 286px);
      @include rem(padding, 16px 20px);
      .boxed-item {
        @include rem(padding, 10px 6px);
        margin: 0;
        @include rem(margin, 0 16px);
        .styled-switch {
          .MuiSwitch-thumb {
            background-color: $clr-green;
          }
        }
        &.switch-gray  {
          .styled-switch {
            .MuiSwitch-thumb {
              background-color: $clr-text-light;
            }
          }
        }
      }
      .state-group {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        color: $clr-text-light;
        [class^='icomoon-'] {
          @include rem(font-size, 32px);
          @include rem(margin-right, 2px);
        }
        .tit {
          font-family: $font-alter;
          font-weight: $w-extrabold;
          line-height: 1;
          span {
            display: block;
            text-transform: uppercase;
          }
        }
        &.active {
          color: $clr-text-dark;
          &.time [class^='icomoon-'] {
            color: $clr-primary;
          }
          &.price [class^='icomoon-'] {
            color: $clr-green;
          }
        }
      }
    }
    /* stat resume */
    .stat-resume {
      @include rem(padding, 16px 20px);
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      .label-group {
        @include rem(margin-bottom, 8px);
        padding-top: 0;
        .form-label {
          font-family: $font-alter;
          @include rem(font-size, 14px);
        }
      }
      p {
        font-family: $font-alter;
        @include rem(font-size, 18px);
        font-weight: $w-bold;
        white-space: nowrap;
      }
      &.highlighted {
        .label-group {
          .form-label {
            font-weight: $w-bold;
            @include rem(font-size, 16px);
          }
        }
        p {
          @include rem(font-size, 24px);
        }
      }
      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        @include rem(top, 10px);
        @include rem(bottom, 10px);
        @include rem(width, 1px);
        background-color: rgba($clr-black, 0.08);
      }
    }
    .btn {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      @include rem(font-size, 18px);
    }
  }
}
